// Inter font style
  @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');


  /* 
=====
sass styling
=====
*/
$primary-color: #1d1d1d;
$secondary-color: #ffffff;
$trim-color:#d3d3d3;
$hyperlink-color: #2cba6c;
$border-radius: 8px;
$margin: 20px;
$archivo: 'Archivo', sans-serif;
$inter: 'Inter', sans-serif;
$padding: 15px;
$heading-bold: 1.3rem;
/* 
=====
sass styling
=====
*/

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0px;
  background: $primary-color;
}

Nav {
  font-family: $inter;
}
.a-link {
  background: #005B8F;
  // old colour - #2cba6c 
  font-weight: 800;
  color: $secondary-color;
}

::-moz-selection {
  color: $secondary-color;
  // background: #0F8556;
  background: #005B8F;
}
::selection {
  color: $secondary-color;
  // dark green - #054820
  background: #005B8F;
}
.bg-white {
  background-color: transparent !important;
  width: 35%;
  margin: auto;
}
.width-body {
  width: 60% !important;
  margin: 30px auto;
}
.blur-3xl {
  display: none !important;
}


//Navbar
header nav {
  background: $primary-color;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  position: sticky;
  top: 0px;
  z-index: 10;
  line-height: 80px;
}
ul a {
  color: white;
  align-items: center;
  position: relative;
  text-decoration: none;
//   padding: 20px;
  margin: 30px;
  height: 100%;
  cursor: pointer;
  margin-top: 0px;
}
ul a:hover {
    color: #ffffff;
  }
ul li a::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
ul a:hover::before {
    transform: scaleX(1);
  }
.logo a {
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: left;
  padding: 0 !important;
}
.logo a:hover {
  background: none;
  color: none;
  text-decoration: none !important;
}
.crest { 
  margin: 20px;
  height: 40px;
}
.arrowIcon {
  display: none;
  color: $trim-color;
  width: 100%;
  margin: -45px;
}
.navContainer {
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1110px;
}
nav .burgerMenu {
  display: none;
  position: absolute;
  top: 2rem;
  right: 0.75rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 1.7rem;
  z-index: 1;
}
nav .burgerMenu span:nth-child(1), span:nth-child(2), span:nth-child(3) {
  height: 0.2rem;
  width: 100%;
  background-color: white;
  border-radius: 0.2rem;
}
nav .burgerMenu span:nth-child(2) {
  height: 0.2rem;
  width: 60%;
}
nav .burgerMenu span:nth-child(3) {
  height: 0.2rem;
  width: 30%;
}
nav ul {
  display: inline-flex;
}
nav ul.open {
  display: flex;
  background: $primary-color;
}


@media screen and (max-width: 1024px) {
.sm\:grid-cols-12 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
.top-\[calc\(100\%-13rem\)\] {
  top: calc(100% - 47rem) !important;
}
}

@media screen and (max-width: 950px) {
  body {
  overflow-y: hidden;
  overflow-x: hidden;
}
  .navContainer {
    padding: 0px 70px;
}
  .sm\:grid-cols-12 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}
  .sm\:top-\[calc\(10\%-3rem\)\] {
  top: calc(10% - -26rem) !important;
}
  .sm\:w-\[47\.1875rem\] {
  width: 69.1875rem !important;
}
.sm\:left-\[calc\(50\%\+34rem\)\] {
  left: calc(50% + 21rem) !important;
}
}

@media screen and (max-width: 850px) {
  nav .burgerMenu {
    display: flex;
    right: 4rem;
  }
  nav {
    flex-direction: column;
    align-items: start;
  }
  .navContainer {
    padding: 0;
    display: block;
  }
  ul a {
    color: white;
    align-items: center;
    position: relative;
    text-decoration: none;
    padding: 10px;
    margin: 30px auto;
    // height: 100%;
    cursor: pointer;
    margin-top: 0px;
    width: 50%;
  }
  ul a:hover {
      color: $primary-color;
      background: #ffffff;
    }
  ul a:hover::before {
      transform: none;
    }
  nav ul {
    display: none;
    flex-direction: column;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 100px);
    width: 100%;
    margin-bottom: 0.25rem;
  }
  nav ul.open {
    display: grid;
    height: 100vh;
    width: 100vw;
    z-index: 0;
    text-align: center;
  }
  nav ul a {
    margin-top: 10rem;
    font-size: 2rem;
  }
  .logo {margin-left: 4rem;}
  .arrowIcon {
    display: flex;
    color: $trim-color;
    width: 200%;
    margin: -60px;
  }
  .sm\:py-10 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
}

@media screen and (max-width: 768px) {
  .top-\[calc\(100\%-13rem\)\] {
    top: calc(100% - 58rem) !important;
}
}

@media screen and (max-width: 750px) {
  nav ul a {
    margin-top: 2rem;
    font-size: 2rem;
  }
}

@media screen and (max-width: 720px) {
  body {
    overflow-y:visible;
   }
  .sm\:py-10 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .px-6 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
}
}

@media screen and (max-width: 600px) {
  nav ul a {
    font-size: 2rem;
    margin-top: 5rem;
}
   nav ul {
    grid-template-rows: repeat(6, 106px);
  }
  .sm\:py-10 {
    padding-top: 1.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .sm\:left-\[calc\(50\%\+34rem\)\] {
    left: calc(50% + 39rem) !important;
}
  .sm\:top-\[calc\(10\%-3rem\)\] {
    top: calc(1% + -16rem) !important;
  }
.feature-image {
    border-radius: 15px !important;
}
}

@media screen and (max-width: 500px) { 
.logo {
    margin-left: 2rem;
}
nav .burgerMenu {
    right: 2rem;
}
nav ul.open {
    margin-top: 0em;
}
nav ul a {
  margin-top: 5rem;
}
.sm\:left-\[calc\(50\%\+34rem\)\] {
  left: calc(50% + 21rem) !important;
}
.top-\[calc\(100\%-13rem\)\] {
  top: calc(100% - 60rem) !important;
}
}

@media screen and (max-width: 400px) {
nav ul.open {
    margin-top: 0em;
}
nav ul a {
  margin-top: 3rem;
}
.landing-heading, .landing-sub-heading {
  font-size: 2rem !important;
}
.pt-14 {
  padding-top: 1.5rem !important;
  padding-bottom: -3.5rem !important;
}
.px-6 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
// .blur-3xl {
//   display: none;
// }
}
  


// Landing
.landing-body {
  width: 60%;
  margin: 5px auto;
}
.my-20 {
  margin-bottom: 8rem ;
}
.hero-intro {
  font-family: $archivo;
  color: $secondary-color;
  font-size: 2.4rem;
}
#hero-job {
  font-family: $inter;
  font-size: 1.5rem;
}
.feature-image {
  width: 100%;
  border-radius: 35px;
  margin-bottom: 30px;
  opacity: 0.7;
}
.feature-image:hover {
  opacity: 100%;
  transition: 0.3s;
}
.feature-caption {
  font-family: $archivo;
  font-style: italic;
}
.feature-bio {
  font-style: italic;
}
.heading-two {
  font-family: $archivo;
  color: $secondary-color;
  font-size: 1.5rem;
  text-align: center;
}
.learn-box,.read-box,.game-box {
  // border: 0.5px solid $trim-color;
  border-radius: 50px;
  text-align: center;
  color: $secondary-color;
  padding: 30px;
  font-family: $inter;
  box-shadow: 0px 2px 4px rgba(225, 217, 217, 0.15), 
              0px 4px 8px rgba(66, 65, 65, 0.15), 
              0px 8px 16px rgba(0,0,0,0.15), 
              2px 4px 0px rgba(167, 164, 164, 0.15)             

}
.work-icon {
  width: 40px;
  margin: 15px auto;
}

@media screen and (max-width: 1800px) {
.bg-white {width: 40% !important;}
}

@media screen and (max-width: 1650px) {
.bg-white {width: 45% !important;}
}

@media screen and (max-width: 1550px) {
.bg-white {width: 50% !important;}
}

@media screen and (max-width: 1340px) {
  .bg-white {width: 60% !important;}
}

@media screen and (max-width: 1024px) {
  .bg-white {width: 65% !important;}
  .my-20 {
    margin-top: 3rem !important;
    margin-bottom: 6em !important;
  }
  .my-10 {margin-top: 1rem !important;}
  .mt-20 {margin-top: 8rem !important;}
}

@media screen and (max-width: 850px) {
  .bg-white {width: 70% !important;}
  .heading-two {font-size: 1.3rem;}
}

@media screen and (max-width: 600px) {
  .hero-intro {font-size: 1.8rem;}
  #hero-job {font-size: 1.2rem;}
  .grid-cols-3 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}
  .my-20 {margin-top: 4rem !important;}
}

@media screen and (max-width: 500px) {
  .bg-white {width: 80% !important;}
  .my-20 {margin-top: 3rem !important;}
}


// About
.about-heading {
  font-family: $archivo;
  color: $secondary-color;
  font-size: 2rem;
  text-transform: uppercase;
  padding: 15px;
  margin: 20px auto;
}
.sm\:py-10 {
  padding-bottom: 6.5rem !important;
}
.imagemiddle {
  margin-left: 0em;
  margin-bottom: 20px;
  border-radius: 8px;
}
.heading-about {
  font-family: $archivo;
  color: $secondary-color;
  font-size: 1.8rem;
  text-align: center;
}
.heading-about-two {
  font-family: $archivo;
  color: $trim-color;
  font-size: 1.8rem;
  text-align: center;
}
.about-email {
  width: 16px;
  display: inline-flex;
  margin: 5px 15px 5px 10px;
  fill: $trim-color;
}
#text-about {
  color: white;
  width: 65% !important;
  margin: 30px auto;
  font-family: $inter;
  font-size: 1rem;
  font-size-adjust: none !important;
}
.embed {
  width: 15px;
  display: inline-flex;
  margin: 5px 15px 5px 5px;
  fill: $trim-color;
}
.tool-link1, .tool-link2 {
  z-index: 1 !important;
  opacity: 0.7;
}
.tool-link1:hover, .tool-link2:hover {
  opacity: 100%;
  transition: 0.3s;
}
.tools-heading {
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 1.3rem;
  font-family: $archivo;
}
.tools-box {
  // border: 0.5px solid $trim-color;
  border-radius: 50px;
  color: $secondary-color;
  padding: 30px;
  width: 70%;
  margin: auto;
  font-family: $inter;
  box-shadow: 0px 2px 4px rgba(225, 217, 217, 0.15), 
              0px 4px 8px rgba(66, 65, 65, 0.15), 
              0px 8px 16px rgba(0,0,0,0.15), 
              2px 4px 0px rgba(167, 164, 164, 0.15)             

}
.mt-10 {margin-top: 4.5rem !important;}

// @media screen and (max-width: 1800px) {
// .blur-3xl {display: none !important;}
// }

@media screen and (max-width: 1024px) {
body {overflow-y: auto;}
#text-about {width: 100%;}
}

@media screen and (max-width: 850px) {
.tools-box {width: 100%;}
#text-about { width: 100% !important;}
}

@media screen and (max-width: 720px) {
.width-body {width: 70%;}
.sm\:py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 4rem !important;
}
}

@media screen and (max-width: 500px) {
.width-body {width: 90%;}
#text-about {width: 90%;}
.about-email {margin: 5px 5px 5px 10px;}
}

@media screen and (max-width: 400px) {
  .heading-about, .heading-about-two {font-size: 1.6rem;}
}

@media screen and (max-width: 360px) {
.heading-about, .heading-about-two {
  font-size: 1.5rem !important;}
}

@media screen and (max-width: 320px) {
.bg-white {width: 90% !important;}
}


//Projects
.card-body {
  width: 50% !important;
  margin: 0 auto;
}
.project-heading{
  font-family: $archivo;
  color: $secondary-color;
  font-size: 2rem;
  text-transform: uppercase;
  padding: 15px;
  margin: 20px auto;
  margin-left: 8em;
}
.col-3 {
  width: 100%;
  display: inline-flex;
  padding: $padding;
}
.card {
  border-radius: 8px;
  max-width: 100%;
  display: flex;
  margin: 15px auto;
}
.card:hover {
  transform: translateY(-7px);
  transition: 0.3s;
  opacity: 0.4;
  box-shadow: 0px 2px 4px rgba(225, 217, 217, 0.15), 
  0px 4px 8px rgba(66, 65, 65, 0.15), 
  0px 8px 16px rgba(0,0,0,0.15), 
  2px 4px 0px rgba(167, 164, 164, 0.15)  
}
.card-image {
  width: 50%;
  height: auto;
  border-radius: 40px;
  padding: 30px;
}
.card-content {
  color: $secondary-color;
  padding: 30px;
}

#card-title {
  font-family: 'Archivo', sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.view-btn {
  font-weight: 700;
  font-family: $archivo;
  background-color: $hyperlink-color;
  color: $secondary-color;
  padding: 3px;
  text-decoration: underline;
}

@media screen and (max-width: 2250px) {
  .card-body {
    width: 65% !important;
  }
}

@media screen and (max-width: 1700px) {
  .card-body {
    width: 70% !important;
  }
  .project-heading{
    margin-left: 4em;
  }
}

@media screen and (max-width: 1610px) {
.col-3 {
    width: 25%;
    display: inline-flex;
    padding: 8px;
}
.width-body {
  width: 70% !important;
}
.card-image {
  width: 50%;
  height: auto;
}
}

// @media screen and (max-width: 1550px) {
//   .width-body {width: 65%;}
// }

@media screen and (max-width: 1250px) {
  .card-body {
    width: 75% !important;
  }
}

@media screen and (max-width: 1230px) {
  .width-body {width: 80%;}
  .col-3 {
    width: 25%;
    padding: 7px;
  }
  .project-heading{
    margin-left: 2em;
  }
}

@media screen and (max-width: 1185px) {
  .card-body {
    width: 80% !important;
  }
}

@media screen and (max-width: 1150px) {
  .col-3 {
    width: 33.333%;
    padding: 10px;
}
}

@media screen and (max-width: 1100px) {
  .card-body {
    width: 85% !important;
  }
}

@media screen and (max-width: 1024px) {
.width-body {
    width: 80% !important;
}
.col-3 {
    width: 50%;
    display: inline-flex;
  }
  .card-body {
    width: 90% !important;
  }
}

@media screen and (max-width: 950px) {
  .card-body {
    width: 95% !important;
  }
}
  
  @media screen and (max-width: 850px) {
  .card {
    display: inline-flex;
  }
  .width-body {
    width: 100% !important;
  }
  .card-body {
    width: 80% !important;
  }
  .project-heading {
    margin-left: 3em;
  }
  .card-image img {
    border-radius: 15px;
    padding: 5px;
  }
  #card-title {
    margin-bottom: 10px;
  }
  .card-content {
    padding: 5px;
    margin-top: 20px;
  }
  }

@media screen and (max-width: 600px) {
.col-3 {
    width: 49%;
    display: inline-block;
    padding: 6px;
}
.project-heading {
  margin-left: 2em;
}
.card {
  display: inline-block;
}
.card-image {
  width: 100%;
  padding: 10px;
  border-radius: 25px;
}
.card-content {
  margin-top: 5px;
  padding: 20px;
}
#card-title {
  font-size: 1.2rem;
}
}

@media screen and (max-width: 500px) {
.col-3 {width: 100%;}
.project-heading {
  margin-left: 1em;
}
.card-body {
  width: 90% !important;
}
}


// Projects Pages
.project-body {
  width: 70%;
  margin: auto;
  margin-top: 80px;
}
.project-content {
  color: $secondary-color;
  font-size: 1.1rem;
  width: 60%;
  margin: auto;
}
.project-heading-default{
  font-family: $archivo;
  color: $secondary-color;
  font-size: 2.5rem;
  text-transform: uppercase;
  padding: 15px;
  margin: 20px auto;
  text-align: center;
}
.gap-12 {
  gap: 1rem !important;
}
#demo-btn {
  text-align: center;
  margin: 10px auto;
  margin-top: -2rem;
  font-family: $archivo;
  font-weight: 700;
  font-size: 1.6rem;
  width: 300px;
  height: 60px;
  cursor: pointer;
}
.github-logo {
  width: 45px;
  margin: auto;
  margin-top: 4em;
  margin-bottom: 2em;
}
.tool-section {
  text-align: center;
}
#tool-tags {
  margin: 10px;
}
#screenshots {
  margin-top: 50px;
}
.screenshot-captions {
  color: $secondary-color;
  margin-top: 10px;
  margin-bottom: 40px;
  text-align: center;
  font-style: italic;

}

@media screen and (max-width: 1740px) {
.gap-12 {gap: 0rem !important;}
}

@media screen and (max-width: 1500px) {
.gap-12 {gap: 0rem !important;}
  .project-content {width: 70% !important;}
}

@media screen and (max-width: 1400px) and (min-width: 1024px) {
  .project-body {
    width: 100%;
    margin: auto;
    margin-top: 80px;
}
}

@media screen and (max-width: 1100px) {
.project-content {width: 70% !important;}
.my-8 {
  margin-top: 1rem !important;}
}

@media screen and (max-width: 1023px) {
.project-content {margin-top: 4em;}
}

@media screen and (max-width: 950px) {
.project-content {width: 90% !important;}
}

@media screen and (max-width: 850px) {
  #screenshots {padding: 10px;}
}

@media screen and (max-width: 768px) {
.project-content {width: 100% !important;}
#screenshots {
  margin-top: 50px;
  width: 70%;
  margin: 20px auto ;
}
}

@media screen and (max-width: 600px) {
  .project-content {width: 100%;}
  #screenshots {width: 100%;}
  #demo-btn {width: 100% !important;}
}

@media screen and (max-width: 500px) {
  .project-content {width: 100%;}
}

@media screen and (max-width: 480px) {
  .tool-section {
    width: 100%;
    margin: auto;
}
}

@media screen and (max-width: 320px) {
  #demo-btn {width: 100%;}
}




// // Contact
// #contact-content {
//   width: 70%;
//   margin: auto;
//   margin-top: 50px;
//   margin-bottom: 10em;
//   color: $secondary-color;
//   font-size: 1.1rem;
// }
// .hr-lines:before{
//   content:" ";
//   display: block;
//   height: 2px;
//   width: 200px;
//   position: absolute;
//   top: 50%;
//   left: 0;
//   background: $secondary-color;
// }
// .hr-lines{
//   position: relative;
//   max-width: 650px;
//   margin: 70px auto;
//   text-align: center;
//   color: $secondary-color;
// }


// @media screen and (max-width: 4000px) {
//   #contact-content {width: 40%;}
// }

// @media screen and (max-width: 2300px) {
//   #contact-content {
//     width: 40%;
//   }
//   .hr-lines:before{
//     width: 150px;
//   }
// }

// @media screen and (max-width: 1900px) {
//   #contact-content {
//     width: 50%;
//   }
//   .hr-lines:before{
//     width: 150px;
//   }
// }

// @media screen and (max-width: 1300px) {
//   #contact-content {width: 60%;}
// }

// @media screen and (max-width: 950px) {
//   #contact-content {width: 70%;}
// }

// @media screen and (max-width: 850px) {
//   #contact-content {width: 80%;}
// }

// @media screen and (max-width: 720px) {
//   .hr-lines:before{width: 100px;}
// }

// @media screen and (max-width: 540px) {
//   .hr-lines:before{width: 70px;}
// }

// @media screen and (max-width: 440px) {
//   #contact-content {
//     width: 100%;
// }
// .hr-lines:before{
//     width: 85px;
//   }
// }

// @media screen and (max-width: 400px) {
//   .hr-lines:before{width: 60px;}
// }


